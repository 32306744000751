var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "things-model-index-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("模板功能")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { staticClass: "page-menu-sider", attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "模板功能", bordered: false } },
                    [
                      _c(
                        "a-menu",
                        {
                          attrs: {
                            "selected-keys": [_vm.$route.name],
                            theme: "light",
                          },
                        },
                        [
                          _c(
                            "a-menu-item",
                            { key: "attribute" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/dashboard/things-model/attribute",
                                  },
                                },
                                [_vm._v("基本属性")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            { key: "method" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/dashboard/things-model/method",
                                  },
                                },
                                [_vm._v("基本服务")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item",
                            { key: "event" },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: "/dashboard/things-model/event",
                                  },
                                },
                                [_vm._v("基本事件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [_c("router-view")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }